.toggleWrapper {
  padding: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.toggleLabel {
  margin: 4px;
}

.toggleControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
