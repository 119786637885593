.switchContainer {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
}
.switchContainerDisabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.switchCheckox {
  display: none;
}

.switchTrack {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 16px;
  background-color: #cccccc;
}
.switchTrackChecked {
  background-color: #bb86fc;
}

.switchButton {
  position: absolute;
  top: 2px;
  bottom: 2px;
  right: 21px;
  left: 2px;
  background-color: #ffffff;
  border-radius: 8px;
  transition: all 100ms ease;
}
.switchButtonChecked {
  right: 2px;
  left: 21px;
}
