.formResultContainer {
  background-color: #ccc;
  min-height: 300px;
  border-radius: 8px;
  padding: 8px;
  h2 {
    margin: 10px;
    color: black;
  }
}

.formResultContent {
  color: #222222;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
}
