.errorBoundaryWrapper {
  background-color: #ececec;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: 100%;
}

.errorBoundaryLargeText {
  font-size: 36px;
  color: crimson;
  margin: 10px;
}
